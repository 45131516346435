import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Footer = () => {
  return (
    <footer>
      <div className="footer_container">
        <div className="logo_footer">
          <a href="https://agelu.tips/" target="_blank" rel="noreferrer">
            <StaticImage
              placeholder="none"
              alt="Agelu"
              src="../images/logo_agelu.png"
              layout="fullWidth"
            />
          </a>
        </div>
        <div className="nav_footer">
          <ul>
            <li>
              <a
                href="https://agelu.tips/terms/terms.html"
                target="_blank"
                rel="noreferrer"
              >
                Agelu利用規約
              </a>
            </li>
            <li>
              <a
                href="https://agelu.tips/terms/transaction.html"
                target="_blank"
                rel="noreferrer"
              >
                特定商取引法に基づく表記
              </a>
            </li>
            <li>
              <a
                href="https://agelu.tips/terms/privacy.html"
                target="_blank"
                rel="noreferrer"
              >
                個人情報の取り扱いについて
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p className="copyright">© INTERLINK Corp. All Rights Reserved.</p>
    </footer>
  )
}

export default Footer
