// @see https://www.gatsbyjs.com/docs/add-seo-component/
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl,
          defaultImage: image
          twitterUsername,
          robots,
        }
      }
    }
  `)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
    robots = 'noindex',
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={title ? titleTemplate : '%s'}>
      {robots && <meta name="robots" content={robots} />}

      <meta property="og:site_name" content={defaultTitle} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {article && <meta property="og:type" content="article" />}

      {seo.title && (<meta property="og:title" content={seo.title} />)}
      {seo.title && (<meta name="twitter:title" content={seo.title} />)}

      {seo.description && (
        <meta name="description" content={seo.description} />)}
      {seo.description && (
        <meta property="og:description" content={seo.description} />)}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />)}

      {seo.image && (<meta name="image" content={seo.image} />)}
      {seo.image && (<meta property="og:image" content={seo.image} />)}
      {seo.image && (<meta name="twitter:image" content={seo.image} />)}

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seo.title || seo.description || seo.image || twitterUsername && (
        <meta name="twitter:card" content="summary_large_image" />
      )}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
