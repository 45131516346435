import React from 'react'
import logo from '@/images/logo_agelu.png'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'

class Header extends React.Component {
  state = {
    query: '',
  }

  handleInputChange = ({ target: { value, name } }) => {
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    navigate(`/search/?query=${this.state.query}`)
  }

  render() {
    return (
      <header className="wrapper">
        <div className="logo">
          <a href="https://agelu.tips/" target="_blank" rel="noreferrer">
            <StaticImage
              placeholder="none"
              alt="Agelu"
              src="../images/logo_agelu.png"
              layout="fullWidth"
            />
          </a>
        </div>

        {this.props.search && (
          <form className="form2" onSubmit={this.handleSubmit}>
            <div className="form2_inner">
              <input
                className="sbox"
                type="search"
                name="query"
                value={this.state.query}
                onChange={this.handleInputChange}
                placeholder="キーワードを入力してください"
              />
              <input className="sbtn" type="submit" value="検 索" />
            </div>
          </form>
        )}

        <p className="contact_header">
          <a
            className="contact_header_btn"
            href="https://agelu.tips/contact/"
            target="_blank"
            rel="noreferrer"
          >
            お問合せフォーム
          </a>
        </p>
      </header>
    )
  }
}

Header.propTypes = {
  search: PropTypes.bool,
}

export default Header
