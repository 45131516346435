import React from 'react'
import PropTypes from 'prop-types'
import Footer from '@/components/footer'
import Header from '@/components/header'

const Layout = ({ children, search }) => {
  return (
    <div className="main_container">
      <Header search={search} />
      {children}
      <Footer />
    </div>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  search: PropTypes.bool,
}
